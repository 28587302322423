import axiosInstance from "@/service/axios";
import apis from "@/service/apis";
import city from "@/utils/address/city.json"
/**
 * @description 判断类型
 */
export const type = new (function () {
  // 闭包保存类型枚举
  let class2type = {};
  "Boolean,Number,String,Function,Array,Date,RegExp,Object,Error"
    .split(",")
    .forEach((item) => {
      class2type["[object " + item + "]"] = item.toLowerCase();
    });
  return function (param) {
    if (param == null) {
      return String(param);
    }
    return typeof param === "object" || typeof param === "function"
      ? class2type[Object.prototype.toString.call(param)] || "obejct"
      : typeof param;
  };
})();

/**
 *
 * @param url 目标下载接口
 * @param query 查询参数
 * @param fileName 文件名称
 * @returns {*}
 */
export function downBlobFile(url, query, fileName, method = "get") {
  let params = {
    url: apis[url],
    method,
    responseType: "blob",
  };
  params[method === "get" ? "params" : "data"] = query;
  return axiosInstance(params).then((response) => {
    // 处理返回的文件流
    const blob = response.data;
    if (blob && blob.size === 0) {
      return;
    }
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function () {
      URL.revokeObjectURL(blob);
      document.body.removeChild(link);
    }, 0);
  });
}

/**
 * 全局防抖函数
 * @param {Function} func - 要防抖的函数
 * @param {Number} delay - 延迟时间（毫秒）
 * @returns {Funtion} - 防抖处理后的函数
 */
export const debounce = (func, delay) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

/**
 * 全局节流函数
 * @param {Function} func - 要节流的函数
 * @param {Number} delay - 间隔时间（毫秒）
 * @returns {Funtion} - 节流处理后的函数
 */
export const throttle = (func, delay) => {
  let timer = null;
  let lastRunTime = 0;

  return function (...args) {
    const context = this;
    const currentTime = Date.now();
    const remainingTime = delay - (currentTime - lastRunTime);

    clearTimeout(timer);

    if (remainingTime <= 0) {
      func.apply(context, args);
      lastRunTime = currentTime;
    } else {
      timer = setTimeout(() => {
        func.apply(context, args);
        lastRunTime = Date.now();
      }, remainingTime);
    }
  };
};

/**
 * 数字千分逗号分隔保留两位小数的方法
 * @param {Number} num - 要格式化的数字
 * @returns {String} - 格式化后的字符串
 */
export const formatNumber = (num, decimalDigits = 2) => {
  num += "";
  return parseFloat(num.replace(/[$,]/g, "")).toLocaleString("en-US", {
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
  });
};

export const formatNumberAbs = (num, decimalDigits = 2) => {
  let absNum = Math.abs(num)
  absNum += "";
  const returnNum = parseFloat(absNum.replace(/[$,]/g, "")).toLocaleString("en-US", {
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
  });
  return returnNum == 0 ? returnNum : `-${returnNum}`
};

// 给小于十的月日时分秒前面补零
export const padZero = (time) => {
  const originalDate = new Date(time);
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, "0");
  const day = String(originalDate.getDate()).padStart(2, "0");
  const hours = String(originalDate.getHours()).padStart(2, "0");
  const minutes = String(originalDate.getMinutes()).padStart(2, "0");
  const seconds = String(originalDate.getSeconds()).padStart(2, "0");
  // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return {
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
  };
};

export const sampleReasonEnum = {
  1: "个人原因暂时离开",
  2: "节假日休息",
  3: "不可控力因素（政策/灾难等）",
};
export const reviewStatusEnum = {
  1: "已通过",
  2: "审核中",
  3: "未通过",
  4: "未认证",
};

// 传入标准时间，返回年月日，传日年月日时，返回标准时间
export const convertDateFormat = (dateString) => {
  // 判断输入的日期格式是标准时间还是年月日格式
  if (
    /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT\+\d{4} \(.+\)$/.test(
      dateString
    )
  ) {
    // 将标准时间格式转换为年月日格式
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    // 将年月日格式转换为标准时间格式
    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);
    const date = new Date(year, month, day);
    return date.toString();
  } else {
    // 如果输入的日期格式不符合要求，则返回空字符串或者其他指示错误的值
    return "Invalid date format：时间格式不正确";
  }
};

export function timestampToDateTime(
  timestamp,
  format = "YYYY-MM-DD hh:mm:ss",
  afterContent = ""
) {
  if (!timestamp) return undefined;
  const date = new Date(timestamp); // 转换为Date对象
  const year = date.getFullYear(); // 获取年份
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 获取月份，转为两位数
  const day = ("0" + date.getDate()).slice(-2); // 获取日，转为两位数
  const hours = ("0" + date.getHours()).slice(-2); // 获取小时，转为两位数
  const minutes = ("0" + date.getMinutes()).slice(-2); // 获取分钟，转为两位数
  const seconds = ("0" + date.getSeconds()).slice(-2); // 获取秒，转为两位数
  return (
    format
      .replace("YYYY", year)
      .replace("MM", month)
      .replace("DD", day)
      .replace("hh", hours)
      .replace("mm", minutes)
      .replace("ss", seconds) + afterContent
  );
}

/**
 * @description: 年月日时分秒毫秒
 * @return {*}
 */
export function formatTimestamp() {
  // 年月日时分秒毫秒
  const date = new Date();
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const milliseconds = date.getMilliseconds().toString().padStart(3, "0");
  return year + month + day + hours + minutes + seconds + milliseconds;
}

/**
 * @description 对象遍历方法
 */
export function eachObject(object, callback) {
  Object.keys(object).forEach((key) => callback(key, object[key]));
}

/**
 * @description 将表单的所有值恢复为初始值
 */
export function resetForm(object, excludes = []) {
  eachObject(object, (key, value) => {
    // 排除Avue的枚举映射字段, excludes参数可以跳过特定字段
    if (!key.startsWith("$") && !excludes.includes[key]) {
      switch (type(value)) {
        case "string":
        case "number":
          object[key] = "";
          break;
        case "array":
          object[key] = [];
          break;
        case "object":
          object[key] = {};
        default:
          break;
      }
    }
  });
}
// 通过url下载文件到本地
export const DownloadUrlFile = (url, filenamePrefix) => {
  // 使用fetch API从URL获取数据
  fetch(url)
    .then(response => response.blob())  // 将响应转换为Blob对象
    .then(blob => {
      // 创建一个指向Blob的URL
      const blobUrl = URL.createObjectURL(blob);
      // 创建一个a元素
      const link = document.createElement('a');
      link.href = blobUrl; // 设置href为Blob的URL
      // 提取文件类型
      const extension = url.split('.').pop();
      // 设置完整的文件名
      const filename = `${filenamePrefix}.${extension}`;
      link.download = filename; // 设置下载属性为构建的文件名

      // 模拟点击该链接
      document.body.appendChild(link);
      link.click();

      // 清理：移除链接和释放Blob URL
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    })
    .catch(error => console.error('Error downloading the file:', error));
}

export let VueformDefaultTime = ['00:00:00', '23:59:59']


/*
  根据codes获取city.json文件中对应的name
  codes => [11, 1100, 110101] => [省code, 市code, 区code]
*/
export const getCityName = ( codes) => {
  let currentLevel = city.children;  // 当前层级
  const result = [];  // 用于存储匹配的 name

  for (let i = 0; i < codes.length; i++) {
    currentLevel = currentLevel.find(item => item.code === codes[i]);  // 查找匹配的省、市或区
    if (!currentLevel) return null;  // 如果找不到对应项，返回 null
    
    result.push(currentLevel.name);  // 将匹配的 name 存入结果数组
    
    // 如果还有下一级，进入下一层
    if (currentLevel.children) {
      currentLevel = currentLevel.children;
    }
  }
  
  // 如果 result 数组不为空且有多个元素，则合并成一个字符串
  if (result.length > 1) {
    // 使用 Set 去重后连接为一个字符串
    return Array.from(new Set(result)).join('');
  }

  // 如果只有一个元素，直接返回该元素
  return result[0] || null;
}


